<template>
  <div
    class="min-w-screen min-h-screen bg-blue-100 flex items-center p-5 lg:p-20 overflow-hidden relative"
  >
    <div
      class="flex-1 min-h-full min-w-full rounded-3xl bg-white shadow-xl p-10 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left"
    >
      <div class="w-full">
        <div class="mb-10 md:mb-20 text-gray-600 font-light">
          <h1 class="font-black uppercase text-3xl lg:text-5xl text-indigo-700 mb-5">
            Privacy Policy
          </h1>
          <h3 class="text-xl">PT Saba Indomedika</h3>
          <h3 class="text-xl mb-5">Terakhir diperbarui: 26 Maret 2025</h3>
          <div class="space-y-4">
            <div class="space-y-2">
              <h2 class="font-black uppercase text-2xl text-indigo-700">Pendahuluan</h2>
              <p class="text-justify">
                Perusahaan Saba Indomedika berkomitmen untuk melindungi privasi dan keamanan data
                pelanggan dalam penggunaan sistem informasi kami. Kebijakan Privasi ini menjelaskan
                bagaimana kami mengumpulkan, menggunakan, mengungkapkan, dan melindungi informasi
                pribadi Anda.
              </p>
            </div>
            <div class="space-y-2">
              <h2 class="font-black uppercase text-2xl text-indigo-700">
                Jenis Informasi yang Dikumpulkan
              </h2>
              <p class="text-justify">
                Kami dapat mengumpulkan berbagai jenis informasi yang diberikan oleh pengguna,
                termasuk tetapi tidak terbatas pada:
              </p>
              <ul class="list-disc ml-5">
                <li>
                  Nama, alamat email, dan informasi kontak lainnya yang diperlukan untuk mendukung
                  komunikasi.
                </li>
                <li>
                  Data tiket, termasuk deskripsi permasalahan, permintaan layanan, serta lampiran
                  file yang diunggah oleh pengguna.
                </li>
                <li>
                  Informasi teknis seperti jenis perangkat, sistem operasi, dan waktu akses untuk
                  meningkatkan pengalaman pengguna.
                </li>
              </ul>
              <p class="text-justify">
                Kami tidak mengumpulkan informasi pribadi yang tidak relevan dengan operasional
                aplikasi ini.
              </p>
            </div>
            <div class="space-y-2">
              <h2 class="font-black uppercase text-2xl text-indigo-700">Akses File</h2>
              <p class="text-justify">
                Aplikasi ini memerlukan izin akses file pada perangkat pengguna untuk memungkinkan
                pengunggahan dokumen atau gambar yang diperlukan dalam tiket layanan. Akses ini
                diperlukan agar pengguna dapat melampirkan file ke dalam tiket yang sedang berjalan.
                Kami hanya mengakses file yang dipilih oleh pengguna dan tidak akan mengambil data
                lain tanpa persetujuan.
              </p>
            </div>
            <div class="space-y-2">
              <h2 class="font-black uppercase text-2xl text-indigo-700">
                Kerahasiaan dan Keamanan Data
              </h2>
              <p class="text-justify">
                Kami berkomitmen untuk menjaga keamanan informasi pengguna dan menerapkan
                langkah-langkah teknis serta prosedural guna mencegah akses tidak sah, penggunaan
                yang tidak sah, atau pengungkapan data tanpa izin.
              </p>
              <p class="text-justify">Kami tidak akan:</p>
              <ul class="list-disc ml-5">
                <li>
                  Menggunakan data pengguna untuk tujuan lain di luar layanan yang diberikan oleh
                  aplikasi ini.
                </li>
                <li>
                  Menjual, menyewakan, atau membagikan data pengguna kepada pihak ketiga tanpa
                  persetujuan resmi dari perusahaan.
                </li>
              </ul>
            </div>
            <div class="space-y-2">
              <h2 class="font-black uppercase text-2xl text-indigo-700">
                Penyimpanan dan Penghapusan Data
              </h2>
              <p class="text-justify">
                Informasi yang dikumpulkan dalam aplikasi ini akan disimpan selama periode maksimal
                5 tahun sejak terakhir kali digunakan. Setelah periode tersebut, data akan dihapus
                secara permanen dari server perusahaan melalui prosedur penghapusan yang aman.
              </p>
              <p class="text-justify">
                Pengguna dapat mengajukan permintaan penghapusan data lebih awal dengan menghubungi
                tim IT PT Saba Indomedika.
              </p>
            </div>
            <div class="space-y-2">
              <h2 class="font-black uppercase text-2xl text-indigo-700">
                Hak Cipta dan Kekayaan Intelektual
              </h2>
              <p class="text-justify">
                Seluruh hak cipta terkait aplikasi ini, termasuk desain, teks, grafik, pemrograman
                perangkat lunak, serta struktur dan organisasi sistem, merupakan milik PT Saba
                Indomedika. Hak-hak ini dilindungi oleh hukum kekayaan intelektual yang berlaku.
              </p>
              <p class="text-justify">Pengguna atau pihak lain tidak diperbolehkan untuk:</p>
              <ul class="list-disc ml-5">
                <li>
                  Menyalin, mendistribusikan, atau memodifikasi aplikasi ini tanpa izin resmi dari
                  PT Saba Indomedika.
                </li>
                <li>
                  Menggunakan aplikasi ini untuk tujuan komersial atau di luar ruang lingkup
                  perusahaan tanpa persetujuan tertulis.
                </li>
              </ul>
            </div>
            <div class="space-y-2">
              <h2 class="font-black uppercase text-2xl text-indigo-700">
                Kesamaan dengan Aplikasi Lain
              </h2>
              <p class="text-justify">
                Fitur atau alur pengembangan dalam aplikasi ini mungkin memiliki kesamaan dengan
                aplikasi lain, baik secara sengaja maupun tidak. Namun, kami menegaskan bahwa
                aplikasi ini dikembangkan secara independen untuk memenuhi kebutuhan spesifik PT
                Saba Indomedika dan bukan merupakan replikasi dari aplikasi lain.
              </p>
            </div>
            <div class="space-y-2">
              <h2 class="font-black uppercase text-2xl text-indigo-700">Hukum Yang Berlaku</h2>
              <p class="text-justify">
                Kebijakan Privasi ini diatur berdasarkan hukum Republik Indonesia dan Anda
                diwajibkan tunduk kepada semua peraturan yang berlaku di Indonesia.
              </p>
            </div>
            <div class="space-y-2">
              <h2 class="font-black uppercase text-2xl text-indigo-700">
                Perubahan Kebijakan Privasi
              </h2>
              <p class="text-justify">
                Kebijakan privasi ini dapat mengalami perubahan dari waktu ke waktu untuk
                menyesuaikan dengan kebijakan perusahaan atau regulasi yang berlaku. Perubahan akan
                diinformasikan melalui kanal komunikasi resmi PT Saba Indomedika.
              </p>
              <p class="text-justify">
                Pengguna diharapkan untuk membaca kebijakan privasi ini secara berkala agar memahami
                perubahan yang mungkin terjadi.
              </p>
            </div>
            <div class="space-y-2">
              <h2 class="font-black uppercase text-2xl text-indigo-700">Kontak dan Pertanyaan</h2>
              <p class="text-justify">
                Jika ada pertanyaan, masukan, atau permintaan terkait kebijakan privasi ini, silakan
                hubungi kami melalui:
              </p>
              <p class="text-justify">Email: dicky.kurniawan@sabaindomedika.com</p>
              <p class="text-justify">
                Kami akan berusaha untuk merespons pertanyaan atau permintaan dalam waktu yang wajar
                dan sesuai dengan kebijakan perusahaan.
              </p>
            </div>
          </div>
        </div>
        <div class="mb-20 md:mb-0">
          <button
            class="text-lg outline-none focus:outline-none transform transition-all hover:scale-110 btn text-secondary btn-ghost capitalize font-medium hover:border-transparent"
            @click="$router.replace({ name: 'home' })"
          >
            Go Home
          </button>
        </div>
      </div>
    </div>
    <div
      class="mix-blend-multiply w-64 md:w-96 h-96 md:h-full bg-blue-200 bg-opacity-30 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45 transform"
    ></div>
    <div
      class="w-96 h-full bg-indigo-200 bg-opacity-20 absolute -bottom-96 right-64 rounded-full pointer-events-none -rotate-45 transform"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'privacy-policy'
}
</script>
